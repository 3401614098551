import styled from 'styled-components/macro'
import CheckBig from '../../../ui/icon/icons/CheckBig'

export const FilterContainer = styled.div`
  min-width: 15rem;
  max-width: 15rem;
  padding: 0.75rem;
  align-items: center;
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--Stroke, #e9eaf0);
  background: #fff;
  height: fit-content;

  @media (max-width: 48rem) {
    max-width: 100%;
  }
`

export const FilterHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const FilterTitle = styled.p`
  font-size: 0.875rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  transform: ${props => (props.isOpen ? '' : 'rotate(270deg)')};
`

export const FilterBody = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
export const WrapperSearch = styled.div`
  max-height: 2.375rem;
`

export const ClearButton = styled.div`
  cursor: pointer;
  position: relative;
  left: 12rem;
  bottom: 1.6875rem;
`

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 16rem;
  overflow: scroll;
  gap: 0.5rem;
`

export const FilterReinitialize = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
`

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ValueItem = styled.div`
  cursor: pointer;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0.1875rem;
`

export const CheckboxContainer = styled.div`
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.25rem;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  display: inline-block;
  border-radius: 0.125rem;
  border: 0.0313rem solid #8f95b2;
`

export const Checkbox = styled.input`
  margin-right: 0.3125rem;
  position: absolute;
  cursor: pointer;
  &[type='checkbox'] {
    appearance: none;
    width: 0.75rem;
    height: 0.75rem;
    background-color: #fff;
    border: 0.0625rem solid #00875c;
    border-radius: 0.1875rem;
    position: relative;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    &:checked + svg {
      display: block;
    }
  }
`

export const StyledCheckBig = styled(CheckBig)`
  display: none;
  position: absolute;
  top: 0rem;
  background-color: #65b293;
  border-radius: 0.0625rem;
  width: 100%;
  height: 100%;
  pointer-events: none;
`
