import { useEffect, useState } from 'react'
import { useGetPrescriberAddressByPrescriberId } from '../../services/api/prescriber/prescriber'
import { useGetUserBySub } from '../../services/api/users/users'
import {
  BrandDTO,
  PrescriptionSearchOrder,
  Role,
  TakeFormatType,
} from '../../services/api/types'
import { useInView } from 'react-intersection-observer'
import useDebounce from '../../utils/hooks/useDebounce'
import { useGetAllProductsFromPrescriberFavoriteItems } from '../../services/api/favorites/favorites'
import { useInfiniteQuery } from 'react-query'
import {
  getGetProductsQueryKey,
  getProducts,
} from '../../services/api/product/product'
import { useFormik } from 'formik'
import { ICatalogForm } from './props'
import { useGetAllBrands } from '../../services/api/brands/brands'
import { useGetAllTypesForProduct } from '../../services/api/product-types/product-types'
import { useGetAllTagsForProduct } from '../../services/api/product-tags/product-tags'
import { useGetAllProductLabels } from '../../services/api/product-labels/product-labels'
import { useGetIngredients } from '../../services/api/ingredients/ingredients'
import { useGetAllAllergensForProduct } from '../../services/api/product-allergens/product-allergens'
import { IUseLogicProductsProps } from './type'

export const useLogicProducts = ({
  packagingType,
  SIZE,
  favoriteListId,
  favoriteLists,
}: IUseLogicProductsProps) => {
  // Attributess
  const userRole = localStorage.getItem('role') as Role
  const { ref, entry } = useInView()
  const [productDetailsModal, setProductDetailsModal] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 500)

  // Formik
  const { values, resetForm, dirty, setFieldValue } = useFormik<ICatalogForm>({
    initialValues: {
      brands: [],
      productTypes: [],
      productTags: [],
      includeIngredients: [],
      excludeIngredients: [],
      takeFormat: [],
      productLabels: [],
      searchItemId: '',
      searchType: null,
      allergens: [],
      sortBy: null,
    },
    onSubmit: () => {},
  })

  const filterParams = {
    brands: values.brands?.flatMap(value => value?.id)?.join(','),
    productType: values.productTypes?.flatMap(value => value?.id)?.join(','),
    productTags: values.productTags?.flatMap(value => value?.id)?.join(','),
    takeFormat: mapTakeFormat(
      values.takeFormat?.flatMap(value => value?.id)
    )?.join(','),
    productLabels: values.productLabels?.flatMap(value => value?.id)?.join(','),
    includeIngredients: values.includeIngredients
      ?.flatMap(value => value?.id)
      ?.join(','),
    excludeIngredients: values.excludeIngredients
      ?.flatMap(value => value?.id)
      ?.join(','),
    allergen: values.allergens?.flatMap(value => value?.id)?.join(','),
  }
  const debouncedFields = useDebounce(filterParams, 500)
  const fullParams = {
    packagingType,
    display: true,
    size: SIZE,
    searchItemId: values.searchItemId,
    searchType: values.searchType,
    q: debouncedSearch,
    ...debouncedFields,
    order: values.sortBy,
  }

  // Queries
  const { data: prescriber } = useGetUserBySub(
    { role: userRole },
    {
      query: {
        enabled: !!userRole,
        retry: 1,
      },
    }
  )
  const { data: addressByPrescriber } = useGetPrescriberAddressByPrescriberId(
    prescriber?.id,
    {
      query: { enabled: !!prescriber?.id },
    }
  )
  const { data: allFavoriteLists } =
    useGetAllProductsFromPrescriberFavoriteItems(
      prescriber?.id,
      {
        packagingType,
      },
      {
        query: {
          enabled: !!prescriber?.id,
        },
      }
    )
  const handleSortByChange = (selectedSortBy: PrescriptionSearchOrder) => {
    setFieldValue('sortBy', selectedSortBy)
  }
  const {
    data: products,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isSuccess,
  } = useInfiniteQuery(
    getGetProductsQueryKey(fullParams),
    async ({ pageParam }) => {
      return await getProducts({
        ...fullParams,
        page: pageParam,
      })
    },
    {
      getNextPageParam: page => {
        if (page?.page !== page?.nbPages - 1) {
          return page?.page + 1
        } else {
          return undefined
        }
      },
    }
  )

  const { data: brands } = useGetAllBrands() // marque
  const { data: productTypes } = useGetAllTypesForProduct() // type de produit
  const { data: productTags } = useGetAllTagsForProduct() // objectif santé
  const { data: productLabels } = useGetAllProductLabels() // labels
  const { data: ingredients } = useGetIngredients() // ingredinets (exclude+include)
  const { data: allergens } = useGetAllAllergensForProduct() // allergens
  const takeFormatTypeArray = [
    // format
    'Liquide',
    'Gélule/comprimé',
    'Poudre',
    'Crème',
    'Autre',
  ]

  let brandsArray: BrandDTO[] = brands

  // If the country is 'belgique', filter out the specific brands
  if (addressByPrescriber?.country === 'Belgique') {
    brandsArray = brands?.filter(
      brand =>
        brand.name !== 'Pharma Nord' &&
        brand.name !== 'Herbolistique' &&
        brand.name !== 'NHCO'
    )
  }
  if (addressByPrescriber?.country === 'France') {
    brandsArray = brands?.filter(brand => brand.name !== 'Herbolistique')
  }
  const productsToDisplay = products?.pages?.flatMap(page => page.data)
  const filteredProducts = favoriteListId
    ? productsToDisplay?.filter(
        product => !favoriteLists?.some(favorite => favorite.id === product.id)
      )
    : productsToDisplay

  // Functions
  function mapTakeFormat(takeFormats: string[]) {
    return (
      takeFormats?.flatMap(elt => {
        switch (elt) {
          case 'Liquide':
            return [
              TakeFormatType.CAP,
              TakeFormatType.AMPOULE,
              TakeFormatType.DROPS,
              TakeFormatType.GLASS,
              TakeFormatType.SPRAY,
            ]
          case 'Gélule/comprimé':
            return [
              TakeFormatType.CAPSULE,
              TakeFormatType.SOFT_CAPSULE,
              TakeFormatType.TABLETS,
            ]
          case 'Poudre':
            return [
              TakeFormatType.BAG,
              TakeFormatType.POD,
              TakeFormatType.TABLESPOON,
              TakeFormatType.TABLESPOON,
            ]
          case 'Crème':
            return [TakeFormatType.NUT]
          case 'Autre':
            return [TakeFormatType.PATCH]
          default:
            return []
        }
      }) ?? []
    )
  }

  function changeSearch(value: string) {
    setSearch(value)
  }

  // Effects
  useEffect(() => {
    if (entry?.isIntersecting && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [entry])

  return {
    isLoading,
    isFiltersUsedBoolean: dirty,
    isSuccess,
    productsToDisplay: filteredProducts,
    hasNextPage,
    ref,
    productDetailsModal,
    setProductDetailsModal,
    allFavoriteListsHandles: allFavoriteLists?.map(i => i.handle),
    isFetchingNextPage,
    clearAllFilters: resetForm,
    values,
    setFieldValue,
    search,
    changeSearch,
    debouncedSearch,
    totalAmount: products?.pages[0]?.totalAmount,
    allBrands: brandsArray,
    allProductTypes: productTypes,
    allProductTags: productTags,
    allProductLabels: productLabels,
    allIngredients: ingredients,
    allTakeFormat: takeFormatTypeArray,
    allAllergens: allergens,
    onSortBy: handleSortByChange,
  }
}
