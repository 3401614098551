import { GainsTableGainProps } from './props'
import { Text } from './style'

const GainsTableGain = ({ gain }: GainsTableGainProps) => {
  // Render
  return (
    <Text color={gain ? (gain >= 0 ? '#65B293' : '#F46363') : '#080038'}>
      {gain ? `${gain >= 0 && ''}${gain?.toFixed(2)}€` : '-'}
    </Text>
  )
}

export default GainsTableGain
