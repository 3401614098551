import styled from 'styled-components/macro'

export const Container = styled.div`
  max-height: 85vh;
  overflow: scroll;
  padding: 40px;

  h1 {
    margin-bottom: 15px;
    font-size: 18px;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }

  ul {
    margin-left: 15px;
  }

  p,
  ul {
    font-size: 14px;
    opacity: 0.7;
    margin-bottom: 8px;
  }
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 100;
  left: 5px;
  top: 5px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const InfoWrapper = styled.div`
  display: flex;
`

export const Info = styled.div`
  display: flex;
  font-size: 13px;
  margin-bottom: 10px;
  margin-right: 10px;
  span {
    font-size: 15px;
    margin-right: 5px;
    margin-top: 1px;
  }
`
