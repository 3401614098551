import { Link } from 'react-router-dom'
import { Dialog } from '../../../../components/ui'
import { IconCross } from '../../../../components/ui/icon/icons'
import { RewardDialogProps } from './props'
import {
  Button,
  CloseButton,
  CloseButtonContainer,
  Container,
  ContentContainer,
  HeaderContainer,
  Section,
  SectionDescription,
  SectionTitle,
  Title,
} from './style'

const AccesAcademyDialog = ({ isOpen, onClose }: RewardDialogProps) => {
  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      type='minimalCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={onClose}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <HeaderContainer>
          <Title>Débloquez l’Academie !</Title>
        </HeaderContainer>
        <ContentContainer>
          <Section>
            <SectionTitle>L’Académie c’est quoi ?</SectionTitle>
            <SectionDescription>
              La Simplycure Academy, c’est une sélection de contenus pour vous
              aider à perfectionner vos connaissances en micronutrition. Vous
              avez accès gratuitement à des webinars et ateliers pratiques
              animés par des experts sur des thématiques de santé, l’accès à des
              fiches et vidéos produits ou encore à de la documention pour vos
              clients pour compléter votre conseil.
            </SectionDescription>
          </Section>
          <Section>
            <SectionTitle>Comment y accéder ?</SectionTitle>
            <SectionDescription>
              Pour débloquer l’Académie Simplycure, il vous suffit de réaliser 3
              commandes en utilisant Simplycure. Une fois ces 3 commandes
              réalisées, vous aurez un accès gratuit à vie.
            </SectionDescription>
          </Section>
        </ContentContainer>
        <Link to='/gains'>
          <Button
            style={{
              width: '100%',
            }}
            onClick={() => {
              onClose()
            }}
          >
            Voir tableau de gains
          </Button>
        </Link>
      </Container>
    </Dialog>
  )
}

export default AccesAcademyDialog
