import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  margin-top: 20px;
  background-color: yellow;
  width: 100%;
  height: 600px;
  overflow: scroll;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 15px;
  padding: 25px 30px;
`

export const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  h3 {
    font-size: 16px;
  }
  a {
    text-decoration: none;
    color: inherit;
    font-size: 12px;
    opacity: 0.7;
  }
`

export const List = styled.div`
  overflow: scroll;
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const Img = styled.img`
  width: 30%;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
`

export const TextWrapper = styled.div`
  width: 40%;
  h3 {
    margin-bottom: 3px;
    font-size: 14px;
  }
  p {
    font-size: 11px;
  }
`

export const Button = styled.a`
  cursor: pointer;
  border-radius: 8px;
  background-color: transparent;
  color: inherit;
  padding: 8px;
  font-size: 11px;
  text-decoration: underline;
`

export const SeeMore = styled.button`
  font-size: 12px;
  border: none;
  background-color: transparent;
  text-decoration: underline;
`

export const Info = styled.p`
  margin-top: 5px;
  font-size: 12px;
  opacity: 0.5;
`
