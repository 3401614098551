import { useState } from 'react'
import HubspotForm from '../../hubspot-form'
import { Dialog, Spinner } from '../../ui'
import { IconCross } from '../../ui/icon/icons'
import { SponsorFormDialogProps } from './props'
import {
  CloseButton,
  CloseButtonContainer,
  Container,
  HubspotContainer,
  LoadingContainer,
  NoFormContainer,
  NoFormTitle,
} from './style'
import SponsorFormDialogSuccess from './success'

const SponsorFormDialog = ({
  isOpen,
  onClose,
  formId,
  prescriber,
  succeesMessageTitle,
  succeesMessageDescription,
  successMessageText,
  successImageLink,
}: SponsorFormDialogProps) => {
  // Attributes
  const [isFormLoading, setIsFormLoading] = useState<boolean>(true)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)

  // Functions
  function onFormSubmitted() {
    setIsFormSubmitted(true)
  }

  function onFormReady(form) {
    setIsFormLoading(false)
    // Fill default values
    if (prescriber?.email) {
      form.find('[name="notes_a_l_import"]').val(`${prescriber.email}`).change()

      form.find('[name="email"]').val(`${prescriber.email}`).change()
    }
  }

  function resetForm() {
    setIsFormLoading(true)
    setIsFormSubmitted(false)
  }

  function close() {
    onClose()
    resetForm()
  }

  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      type='defaultCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={close}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        {!formId ? (
          <NoFormContainer>
            <NoFormTitle>Pas de formulaire disponible</NoFormTitle>
          </NoFormContainer>
        ) : (
          <div>
            <LoadingContainer displayed={isFormLoading}>
              <Spinner fullScreen={undefined} />
            </LoadingContainer>

            <HubspotContainer displayed={!isFormLoading}>
              {isFormSubmitted ? (
                <SponsorFormDialogSuccess
                  onClose={close}
                  succeesMessageTitle={succeesMessageTitle}
                  succeesMessageDescription={succeesMessageDescription}
                  successMessageText={successMessageText}
                  successImageLink={successImageLink}
                />
              ) : (
                <HubspotForm
                  formId={formId}
                  onSubmitted={onFormSubmitted}
                  onReady={onFormReady}
                />
              )}
            </HubspotContainer>
          </div>
        )}
      </Container>
    </Dialog>
  )
}

export default SponsorFormDialog
