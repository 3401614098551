import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import {
  Wrapper,
  FooterText,
  Title,
  ImageLogo,
  Subtitle,
  Image,
  ImageText,
  TextImage,
  BackButtonWrapper,
} from './style'
import NakedLayout from '../../components/layout/naked'
import RegisterInformationsForm from '../../components/auth/register-form/basic-informations'
import { BackButton } from '../../components/ui'
import { useHistory } from 'react-router'

const RegisterPage = ({ location }) => {
  //Attributes
  const history = useHistory()

  //Functions
  function handleBackButton() {
    history.push('/portail')
  }

  //Return
  return (
    <NakedLayout isFullBackground={true}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Inscription </title>
      </Helmet>
      <Wrapper>
        <div>
          <BackButtonWrapper>
            <BackButton onClick={handleBackButton} />
          </BackButtonWrapper>
          <ImageLogo
            src={'/assets/images/logo-compliment.png'}
            alt='logo-image'
          />
          <Title>Faisons plus ample connaissance</Title>
          <Subtitle>Nous appréçions connaître nos collaborateurs</Subtitle>
          <RegisterInformationsForm />
          <FooterText>
            Déjà un compte ?{' '}
            <Link
              to={{
                pathname: '/login',
                search: location.search,
              }}
            >
              Connectez-vous
            </Link>
          </FooterText>
        </div>
      </Wrapper>
      <ImageText>
        <div>
          <Image
            src={'/assets/images/image-join-community.png'}
            alt='image-join-community'
          />
          <TextImage>
            Rejoignez une communauté avec plus de 5000 professionnels de santé
          </TextImage>
        </div>
      </ImageText>
    </NakedLayout>
  )
}

export default RegisterPage
