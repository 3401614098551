import { Button } from '../../../components/ui'
import { WelcomContent, WelcomHeader, Buttons } from '../modal/style'
import { CiDeliveryTruck } from 'react-icons/ci'
import { BsCurrencyEuro } from 'react-icons/bs'
import { AiOutlineCustomerService, AiOutlineCheck } from 'react-icons/ai'

const Step1 = ({
  setStep,
  setOpen,
  prescriberName,
  prescriberLastName,
  prescriptionFirstname,
  isMarketPlace,
}) => {
  return (
    <WelcomContent>
      <div>
        <WelcomHeader>
          <h3>Bienvenue {prescriptionFirstname} !</h3>
        </WelcomHeader>
        <p>
          Bonjour {prescriptionFirstname} ! Suite à votre consultation, vous
          trouverez ici toutes les informations concernant votre recommandation
          de compléments alimentaires.
        </p>
      </div>

      <div>
        <WelcomHeader>{/* <h3>Pourquoi nous choisir ?</h3> */}</WelcomHeader>
        <ul>
          <li>
            <span>
              <CiDeliveryTruck />
            </span>
            Livraison à domicile ou en point relais dans les 48 à 72h
          </li>

          <li>
            <span>
              <BsCurrencyEuro />
            </span>
            Satisfait ou remboursé, retours gratuits
          </li>
          <li>
            <span>
              <AiOutlineCheck />
            </span>
            Les meilleurs prix
          </li>
          <li>
            <span>
              <AiOutlineCustomerService />
            </span>
            Notre service client est disponible 24/7
          </li>
        </ul>

        <p>
          {prescriberName} {prescriberLastName}
        </p>
      </div>

      <Buttons>
        <Button onClick={() => setOpen(false)}>Voir ma recommandation</Button>
      </Buttons>
    </WelcomContent>
  )
}

export default Step1
