import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const DialogContainer = styled.div`
  padding: 50px;
  height: 90vh;
  overflow: scroll;

  ${MediaQuery.M_AND_DOWN} {
    padding: 20px;
  }
  ${MediaQuery.S_AND_DOWN} {
    padding: 10px;
  }
`

export const DialogContainerSelectTake = styled.div`
  padding: 20px;
`

export const HeaderDialog = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  img {
    margin-right: 10px;
    object-fit: cover;
    border-radius: 10px;
    width: 60px;
    height: 60px;
  }
`

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
`
export const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 15px;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  right: 20px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
