import SelectCommission from './select-commission'
import { useUpdatePrescriber } from '../../../../services/api/prescriber/prescriber'
import { toast } from 'react-toastify'
import { SelectCommissionTypeProps } from './props'

const SelectCommissionType = ({
  prescriber,
  refetchPrescriber,
}: SelectCommissionTypeProps) => {
  //Attributes
  const accountType = prescriber?.disableCommission
    ? 'commission'
    : 'noCommission'

  //Queries
  const { mutateAsync: updatePrescriber } = useUpdatePrescriber()

  //Function
  function handleUpdatePrescriber(disableCommission) {
    updatePrescriber({
      prescriberId: prescriber?.id,
      data: {
        firstName: prescriber?.firstName || '',
        lastName: prescriber?.lastName || '',
        phoneNumber: prescriber?.phoneNumber || '',
        enterpriseName: prescriber?.enterpriseName,
        tvaNumber: prescriber?.tvaNumber,
        invoiceAddress: prescriber?.invoiceAddress,
        bankAccount: prescriber?.bankAccount,
        activeDiscountId: prescriber?.activeDiscount?.id,
        calendlyCode: prescriber?.calendlyCode,
        isOnboarded: prescriber?.isOnboarded,
        profession: prescriber?.profession,
        disableCommission: disableCommission,
      },
    })
      .then(() => {
        toast.success('Votre profil a été mis à jour avec succès')
        refetchPrescriber()
      })
      .catch(() => {
        toast.error("Une erreur s'est produite, réessayez plus tard")
      })
  }

  //Render
  return (
    <>
      <SelectCommission
        title='Compte à Profit'
        description="Bénéficiez d'une marge pouvant atteindre 10% sur les commandes de vos clients, ou choisissez de transmettre vos avantages sous forme de réductions sur leurs commandes. Simplycure offre automatiquement une réduction de 10% à tous les clients. En configurant un compte à profit, vous avez la possibilité de modifier cette réduction à tout moment."
        selected={accountType === 'noCommission'}
        switchAccount={() => handleUpdatePrescriber(false)}
      />
      <SelectCommission
        title='Compte Sans Profit'
        description="Simplycure offre automatiquement une réduction de 10% à tous les clients. En choisissant cette option, vous ne bénéficierez pas d'avantages supplémentaires sur vos recommandations."
        selected={accountType === 'commission'}
        switchAccount={() => handleUpdatePrescriber(true)}
      />
    </>
  )
}

export default SelectCommissionType
