import { useState } from 'react'
import { Container } from './style'
import { toast } from 'react-toastify'
import { checkVAT, belgium, france, luxembourg } from 'jsvat'
import { Dialog } from '../../../../components/ui'
import { useUpdatePrescriber } from '../../../../services/api/prescriber/prescriber'
import ProfileItem from '../../../settings/payment-section/profile-item'
import { useCreateDefaultDiscountsByPrescriberId } from '../../../../services/api/prescriber/prescriber'
import { refetchMe } from '../../../../utils/helpers/refetch'
import { useQueryClient } from 'react-query'
import { useGetMe } from '../../../../utils/hooks/getMe'

const EditDiscountCodeModal = ({ isDialogOpen, setIsDialogOpen }) => {
  // Attributes
  const queryClient = useQueryClient()
  const { data: prescriber } = useGetMe()

  const { mutateAsync: updatePrescriber } = useUpdatePrescriber()

  const [editField, setEditField] = useState('')
  const [selectValue, setSelectedValue] = useState(
    prescriber?.activeDiscount?.percentage
  )

  const { mutateAsync: createDiscountCodes } =
    useCreateDefaultDiscountsByPrescriberId()

  const onSubmit = async value => {
    if (editField === 'tvaNumber') {
      const vatInfos = checkVAT(value, [belgium, france, luxembourg])
      if (!vatInfos.isValid) {
        toast.error(
          "Le numéro de TVA entré n'est pas correct, merci de ré-essayer."
        )
        return
      }
    }

    let discountSelected = null

    try {
      const defaultDiscounts = await createDiscountCodes({
        prescriberId: prescriber?.id,
      })

      discountSelected = defaultDiscounts?.data?.find(
        d => d.percentage === selectValue
      )
    } catch (err) {
      toast.error(
        "Une erreur s'est produite dans la création de vos promo codes"
      )
      return
    }

    updatePrescriber({
      prescriberId: prescriber?.id,
      data: {
        firstName: prescriber?.firstName || '',
        lastName: prescriber?.lastName || '',
        phoneNumber: prescriber?.phoneNumber || '',
        enterpriseName:
          (editField === 'enterpriseName'
            ? value
            : prescriber?.enterpriseName) || '',
        tvaNumber:
          (editField === 'tvaNumber' ? value : prescriber?.tvaNumber) || '',
        invoiceAddress:
          (editField === 'invoiceAddress'
            ? value
            : prescriber?.invoiceAddress) || '',
        bankAccount:
          (editField === 'bankAccount' ? value : prescriber?.bankAccount) || '',
        activeDiscountId: discountSelected?.id || null,
        calendlyCode: prescriber?.calendlyCode,
        isOnboarded: prescriber?.isOnboarded,
        profession: prescriber?.profession,
        disableCommission: prescriber?.disableCommission,
      },
    })
      .then(() => {
        toast.success('Votre profil a été mis à jour avec succès')
        refetchMe(queryClient)
        setEditField('')
        setIsDialogOpen(false)
      })
      .catch(() => {
        toast.error("Une erreur s'est produite, réessayez plus tard")
        setIsDialogOpen(false)
      })
  }

  // Render
  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      type='default'
      styleContainer={{}}
    >
      <Container>
        <ProfileItem
          defaultValue={selectValue}
          valueSuffix='%'
          label='Pourcentage de réduction'
          openEdit={() => setEditField('discount')}
          isEditing={true}
          description={`Votre code ${
            prescriber?.firstName && prescriber?.lastName && selectValue !== 0
              ? prescriber?.firstName[0] +
                prescriber?.lastName +
                selectValue?.toString()
              : ''
          } offre ${selectValue}% de
        réduction à vos clients qui l'utilisent.`}
          type='select'
          selectValue={selectValue}
          setSelectedValue={setSelectedValue}
          onSubmit={onSubmit}
          firstName={prescriber?.firstName}
          lastName={prescriber?.lastName}
        />
      </Container>
    </Dialog>
  )
}

export default EditDiscountCodeModal
