import { useState } from 'react'
import {
  QuantityChoiceWrapper,
  QuantityChoice,
  HandleQuantity,
  MinusButton,
  Quantity,
  PlusButton,
} from '../style'
import {
  QuantityFlexWrapper,
  Price,
  QuantityButton,
  HandleQuantityFlexWrapper,
} from './style'
import {
  getTakeFormatName,
  getNumberBetweenUnderscores,
  isContentUnit,
  getTakePackagingFormatName,
} from '../../../../utils/helpers/functions'
import { AddToCartProps } from './props'
import toast from 'react-hot-toast'
import { CART_ITEM_COUNT_QUERY, CART_QUERY } from '../../../../utils/constants'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import { CartService } from '../../../../services/cart.service'
import { useUpdateCart } from '../../../../utils/hooks'
import DiscountPrice from '../discount-price'
import Cookies from 'js-cookie'
import { useGetDiscountWithPrescriberByCode } from '../../../../services/api/discount/discount'
import { useLocation } from 'react-router-dom'
import { useGetMe } from '../../../../utils/hooks/getMe'
import { leastVariantsPrice } from '../../../../utils/data'

const AddToCart = ({
  sortedVariants,
  selectedvariant,
  setSelectedvariant,
  dosings,
  product,
  mode,

  hideDiscountPercentage,
  from,
}: AddToCartProps) => {
  // Attributes
  const queryClient = useQueryClient()
  const cart = useQuery(CART_QUERY, () => {
    return CartService.getCart()
  })
  const { updateCart } = useUpdateCart()
  const [numberOfCapsule, setNumberOfCapsule] = useState<number>(1)
  const addItem = useMutation(CartService.addItem, {
    onSuccess: () => {
      updateCart({
        variables: {
          cartId: cart.data?.id,
          lines: [],
        },
      })
      queryClient.invalidateQueries(CART_ITEM_COUNT_QUERY)
      queryClient.invalidateQueries(CART_QUERY)
    },
  })

  const location = useLocation()
  const { data: prescriber } = useGetMe()

  const savedPromoCode = Cookies.get('promoCode')
  const { data: discountWithPrescriberByCode } =
    useGetDiscountWithPrescriberByCode(savedPromoCode?.toUpperCase(), {
      query: {
        enabled: !!savedPromoCode,
      },
    })

  const discountPercentage =
    location.pathname === '/patient-catalog' ||
    location.pathname === '/patient/catalog' ||
    location.pathname === '/patient/cart'
      ? discountWithPrescriberByCode?.percentage
      : prescriber?.activeDiscount?.percentage || 0

  const originalPrice =
    leastVariantsPrice(product?.variants)?.price * numberOfCapsule

  const discountedPrice =
    originalPrice - (originalPrice * discountPercentage) / 100

  // Functions
  const handleOnMinusClick = () => {
    if (numberOfCapsule !== 1) {
      setNumberOfCapsule(Number(numberOfCapsule) - 1)
    }
  }

  const handleOnPlusClick = () => {
    setNumberOfCapsule(Number(numberOfCapsule) + 1)
  }

  const addProductToCart = async () => {
    try {
      toast.promise(
        addItem.mutateAsync({
          merchandiseId: selectedvariant.id,
          quantity: numberOfCapsule,
        }),
        {
          loading: "en cours d'ajout...",
          success: 'Le produit a bien été ajouté!',
          error: 'Oops! Une erreur est survenue.',
        },
        {
          duration: 3000,
        }
      )
    } catch (error: any) {
      toast.error(error)
    }
  }

  // Render
  return (
    <>
      <QuantityChoiceWrapper>
        {sortedVariants?.map((item, index) => {
          return (
            <QuantityChoice
              selected={item?.id === selectedvariant?.id}
              key={index}
              onClick={() => {
                setSelectedvariant(item)
              }}
            >
              {getNumberBetweenUnderscores(item?.title)}{' '}
              {isContentUnit(dosings?.takeFormat)
                ? item?.contentUnit
                : getTakeFormatName(
                    getNumberBetweenUnderscores(item?.title),
                    dosings?.takeFormat
                  )}
            </QuantityChoice>
          )
        })}
      </QuantityChoiceWrapper>
      <QuantityFlexWrapper>
        <HandleQuantityFlexWrapper>
          <HandleQuantity>
            <MinusButton onClick={handleOnMinusClick}>-</MinusButton>
            <Quantity>{numberOfCapsule}</Quantity>
            <PlusButton onClick={handleOnPlusClick}>+</PlusButton>
          </HandleQuantity>
          <QuantityButton onClick={addProductToCart}>Ajouter</QuantityButton>
        </HandleQuantityFlexWrapper>
        <Price $mode={mode}>
          <DiscountPrice
            discountPercentage={
              product?.brand?.name === 'NHCO' ? 0 : discountPercentage
            }
            originalPrice={originalPrice}
            discountedPrice={discountedPrice}
            hideDiscountPercentage={hideDiscountPercentage}
            from={from}
          />
          <span>
            /{' '}
            {getTakePackagingFormatName(
              numberOfCapsule,
              product?.packagingFormat
            )}
          </span>
        </Price>
      </QuantityFlexWrapper>
    </>
  )
}

export default AddToCart
