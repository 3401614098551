import { Dialog } from '../../ui'
import { IconCross } from '../../ui/icon/icons'
import { RewardDialogProps } from './props'
import {
  Button,
  CloseButton,
  CloseButtonContainer,
  Container,
  ContentContainer,
  HeaderContainer,
  Image,
  Section,
  SectionDescription,
  SectionTitle,
  Title,
} from './style'

const RewardDialog = ({ isOpen, onClose }: RewardDialogProps) => {
  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      type='minimalCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={onClose}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <HeaderContainer>
          <Title>Récompenses et objectifs</Title>
          <Image
            src='/assets/images/reward-dialog.png'
            width='366px'
            height='165px'
          />
        </HeaderContainer>
        <ContentContainer>
          <Section>
            <SectionTitle>Mes récompenses</SectionTitle>
            <SectionDescription>
              Chaque commande passée par l’un de vos clients est comptabilisée.
              Vous pouvez débloquer des récompenses et des avantages dès que vos
              clients commandent vos recommandations.
            </SectionDescription>
          </Section>
          <Section>
            <SectionTitle>Le Shop Simplycure</SectionTitle>
            <SectionDescription>
              Le shop Simplycure vous permet d’échanger vos gains contre un tas
              d’avantages. Choisissez l’article qui vous convient et envoyez
              nous un mail à pro@simplycure.com afin de procéder à l’échange de
              vos gains. Le montant de l’article sera déduit de vos gains.
            </SectionDescription>
          </Section>
        </ContentContainer>
        <Button onClick={onClose}>J'ai compris</Button>
      </Container>
    </Dialog>
  )
}

export default RewardDialog
