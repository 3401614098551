import styled from 'styled-components/macro'
import { MediaQuery, ViewMode } from '../../utils/constants'

export const Content = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
`

export const HeaderCatalogueHeader = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 4.6875rem;
  z-index: 2;
  border-radius: 0.875rem;
  flex-direction: column;
`

export const WrapperHeaderCatalogue = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 4.6875rem;
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
  z-index: 1;
  flex-direction: column;
  background-color: #fdfcfe;
`

export const NoFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5rem;
`

export const ProductsContainerInit = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'row' : 'column'};
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 100%;
  gap: 1.25rem;
  justify-content: center;
`
export const ProductsContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(16.25rem, 1fr));
  ${MediaQuery.M_AND_DOWN} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.625rem;
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
    @media (max-width: 20.625rem) {
      grid-template-columns: repeat(1, minmax(6.875rem, 1fr));
    }
  }
`

export const TableWithFilters = styled.div`
  padding-top: 1.25rem;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  background-color: #fdfcfe;
`

export const Table = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SpinnerBottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const SpinnerContainerLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 9.375rem;
`

export const SpinnerContainer = styled.div`
  position: fixed;
  height: 50%;
  width: 50%;
  padding-left: 15.625rem;
  padding-bottom: 7.5rem;
  display: ${({ $isLoading }) => ($isLoading ? 'block' : 'none')}; ;
`

export const WrapperTableContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const WrapperSearchbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.9375rem;
`

export const FilterButton = styled.button`
  display: flex;
  flex-direction: row;
  height: 2.875rem;
  padding: 0rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-System-spacing-8, 0.5rem);
  flex-shrink: 0;
  border-radius: var(--Spacing-System-radius-8, 0.5rem);
  border: 0.0625rem solid var(--Compliment-Light-Green-500, #65b293);
  background: #fff;
  color: var(--Compliment-Light-Green-500, #65b293);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  ${MediaQuery.M_AND_UP} {
    display: none;
  }
`

export const WrapperFilters = styled.div`
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`
