import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'
import { ViewMode } from '../../../utils/constants'

export const ContainerParent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;
  ${MediaQuery.M_AND_DOWN} {
    margin-bottom: 2.25rem;
  }
`

export const Div = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 100%;
`

export const ButtonFavorite = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 0.625rem;
  background: #3a845e;
  border: 0.0625rem solid #3a845e;
  border-radius: 0.5rem;
  margin-top: 0.75rem;
  font-size: 0.875rem;
  text-align: center;
  color: #ffffff;
`

export const Container = styled.div`
  display: flex;
  padding: 1rem;
  width: 19.0625rem;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 calc(33.333% - 2.5rem);
  border-radius: 0.75rem;
  border: 0.0625rem solid #e9eaf0;

  ${MediaQuery.M_AND_DOWN} {
    padding: 0rem;
    border-radius: 0rem;
    border: 0rem;
    justify-content: space-between;
  }
`
export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  align-items: center;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
`

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  height: 6.25rem;
  border-radius: 0.75rem;
  background-color: #e7e8e9;
  margin-bottom: 0.75rem;
  width: 100%;

  ${MediaQuery.M_AND_DOWN} {
    border-radius: 0rem;
    height: 7.625rem;
    margin-bottom: 0.75rem;
  }
`

export const WrapperMobileElements = styled.div`
  ${MediaQuery.M_AND_DOWN} {
    gap: 0.75rem;
  }
`

export const Image = styled.img`
  cursor: pointer;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.875rem;
  ${MediaQuery.M_AND_DOWN} {
    border-radius: 0rem;
  }
`

export const ImageWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  height: auto;
  object-fit: cover;
  border-radius: 0.875rem;
  background-color: grey.100;

  ${MediaQuery.M_AND_DOWN} {
    border-radius: 0rem;
  }
`

export const BrandImage = styled.img`
  object-fit: contain;
  display: flex;
  position: absolute;
  bottom: 0.625rem;
  right: 0.4375rem;
  max-height: 2.8125rem;
  width: 3.75rem;

  ${MediaQuery.M_AND_DOWN} {
    height: 2rem;
    width: 2.625rem;
    right: 0.3125rem;
    bottom: 0.3125rem;
  }
`

export const WrapperFavorite = styled.div`
  display: flex;
  position: absolute;
  background-color: green;
  z-index: 2;
  right: 1.25rem;
`

export const Small = styled.p`
  font-size: 0.875rem;
  line-height: 0.5rem;
`

export const QuantityDescription = styled.p`
  color: #8f95b2;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const NewTag = styled.div`
  position: absolute;
  top: 0.75rem;
  left: 0.5rem;
  color: rgb(8, 66, 152);
  background-color: rgb(207, 226, 255);
  padding: 0.25rem;
  border-radius: 0.1875rem;
  font-size: 0.6875rem;

  ${MediaQuery.M_AND_DOWN} {
    width: 3.3125rem;
    top: 0.625rem;
    left: 0.4375rem;
  }
`

export const Name = styled.h3`
  cursor: pointer;
  color: #080038;
  font-weight: 500;
  align-self: stretch;
  height: 3.75rem;
  font-size: 1rem;
  line-height: 1.25rem;

  ${MediaQuery.M_AND_DOWN} {
    height: auto;
    margin-bottom: 0.75rem;
  }
`

export const FavIcon = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  height: 2rem;
  width: 2rem;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  border-radius: 6.25rem;
  z-index: 10;
  background: #ffffff;
  border: 0.0625rem solid #e7e9f4;
  color: #f9b50b;
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 0.3125rem 0;
`
export const MenuContainerItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.3125rem 0.625rem;
  p {
    margin-bottom: 0.125rem;
  }
`

export const Checkbox = styled.div`
  width: 0.9375rem;
  height: 0.9375rem;
  border: 0.125rem solid #65b293;
  margin-right: 0.625rem;
  border-radius: 0.1875rem;
  background-color: ${props => (props.checked ? '#65b293' : '')};
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.625rem;

  > div {
    flex: 1;
    box-sizing: border-box;

    &:first-child {
      padding-right: 1.25rem;
    }
  }

  label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    margin-bottom: 0.125rem;
    opacity: 0.4;
    letter-spacing: 0.125rem;
    display: inline-block;
    white-space: pre-wrap;
  }

  p {
    font-size: 0.875rem;
    white-space: pre-wrap;
    max-width: 31.25rem;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'row' : 'column'};
  margin-top: ${({ $mode }) => ($mode === ViewMode.GRID ? '1.25rem' : 'none')};
  align-items: flex-end;
`

export const PriceContainer = styled.div`
  flex: 1;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'flex-start' : 'flex-end'};
`

export const Button = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;

  font-size: 1rem;
  font-weight: 900;
  color: ${({ isDisabled }) => (isDisabled ? '#ddd' : Color.DEEP_SEA)};
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0);

  &:hover {
    transform: scale(1.02);
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  ${Button}:not(:last-child) {
    margin-right: 0.625rem;
  }
`

export const LogoSymbiose = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 5.625rem;
`

export const SecondaryTitle = styled.h2`
  font-size: 1.375rem;
  margin-bottom: 1.25rem;
`

export const SecondarySubTitle = styled.h3`
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
`

export const Img = styled.img`
  width: 100%;
  object-fit: contain;
  margin-top: 3.125rem;
`

export const Price = styled.div`
  width: 6.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.25rem;
  font-weight: 500;
  font-family: 'Poppins';
  color: #3a835f;

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: row;
    font-size: 1.125rem;
    align-items: center;
  }
`

export const PriceLimiter = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  line-height: normal;
  font-size: ${({ $mode }) => ($mode === ViewMode.GRID ? '.75rem' : '.875rem')};
  font-family: 'Poppins';
  font-weight: 500;
  color: #393360 !important;
`

export const InactiveText = styled.p`
  > strong {
    font-size: ${({ $mode }) =>
      $mode === ViewMode.GRID ? '1rem' : '1.125rem'};
  }
`

// export const Small = styled.p`
//   font-size: .875rem;
//   line-height: .5rem;
// `

export const DialogHeader = styled.div`
  display: flex;
  padding-bottom: 1.875rem;
  border-bottom: 0.0625rem solid ${Color.MYSTIC};

  > div:nth-child(1) {
    flex: 1;
    padding-right: 3.125rem;
  }
`

export const PillImageContainer = styled.div`
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ $bg }) => `url("${$bg}")`};
`

export const PillImage = styled.img`
  width: 3.75rem;
  height: 3.75rem;
  object-fit: contain;
`

export const DialogTitle = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const DialogSubTitle = styled.h2`
  font-size: 1.25rem;
`

export const DialogBody = styled.div`
  display: flex;
  padding: 3.125rem 0;
`

export const BodyCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const VerticalSeparator = styled.div`
  width: 0.0625rem;
  background-color: ${Color.MYSTIC};
  margin: 0 3.125rem;
`

export const StrongPoints = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding-left: 1.25rem;
`

export const Point = styled.ul`
  position: relative;
  padding: 0 0 1.25rem 0;

  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    position: absolute;
    top: 0.5rem;
    left: -1.375rem;
    background-color: ${Color.MINE_SHAFT};
  }
  &:after {
    content: '';
    width: 0.125rem;
    height: 100%;
    position: absolute;
    top: 0.75rem;
    left: -1.1875rem;
    background-color: ${Color.MINE_SHAFT};
  }

  &:last-child:after {
    display: none;
  }
`

export const PointTitle = styled.p`
  font-size: 1.125rem;
  font-weight: 900;
  white-space: pre-wrap;
`
export const PointText = styled.p`
  font-size: 0.875rem;
  margin-top: 0.625rem;
  white-space: pre-wrap;
`

export const SmallText = styled(Small)`
  margin-bottom: 1.25rem;
  white-space: pre-wrap;
`

export const Title = styled.h1`
  font-size: 1.75rem;
  line-height: 2rem;
  margin-bottom: 1.25rem;
`

export const TakeChoice = styled.button`
  cursor: pointer;
  border: 0.0625rem solid ${Color.MYSTIC};
  padding: 1.25rem;
  background-color: ${Color.TRANSPARENT};
  margin: 0.625rem 0;
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;

  &:hover {
    background-color: ${Color.MYSTIC}55;
  }
`

export const Close = styled.span`
  cursor: pointer;
  position: absolute;
  top: 0.9375rem;
  right: 1.25rem;
`

export const MoreInfo = styled.a`
  font-size: 0.8125rem;
  cursor: pointer;
  font-weight: 300;
  border-bottom: 0.0625rem solid ${Color.DEEP_SEA};
  color: ${Color.DEEP_SEA};
  text-decoration: none;
  white-space: nowrap;
  span {
    margin-left: 0.3125rem;
    font-size: 0.625rem;
  }
`

export const HandleQuantityFlexWrapper = styled.div`
  margin-top: 0.625rem;
  display: flex;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  height: 2.125rem;
  margin-bottom: 0rem;
  border: 0.0625rem solid #e9eaf0;
  width: 11.25rem;
  justify-content: space-between;
  background-color: white;
  ${MediaQuery.S_AND_DOWN} {
    margin: 0;
  }
`

export const HandleQuantity = styled.div`
  display: flex;
  align-items: center;
  ${MediaQuery.M_AND_DOWN} {
    display: ${({ role }) => (role !== 'PRESCRIBER' ? 'none' : 'flex')};
  }
`

export const MinusButton = styled.span`
  cursor: pointer;
  margin: 0 0.625rem;
`

export const PlusButton = styled.span`
  cursor: pointer;
  margin: 0 0.625rem;
`
export const Quantity = styled.span`
  margin: 0 0.625rem;
`

export const QuantityButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #3a835f;
  padding: 0.625rem 1.875rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  font-size: 0.625rem;
  color: white;
`

export const QuantityChoiceWrapper = styled.div`
  flex-wrap: wrap;
  margin-bottom: 0.4375rem;
`

export const QuantityChoice = styled.span`
  white-space: nowrap;
  cursor: pointer;
  margin-right: 0.625rem;
  margin-bottom: 0.1875rem;
  padding: 0.375rem 0.625rem;
  background-color: ${props => (props.selected ? '#39835f' : '#fff')};
  color: ${props => (props.selected ? '#fff' : '#8f95b2')};
  border: 0.0625rem solid #e7e9f4;
  border-radius: 6.25rem;
  font-size: 0.625rem;
`

export const AddButton = styled.button`
  cursor: pointer;
  margin-top: 1.25rem;
  padding: 0.3125rem 1.5rem;
  border-radius: 0.3125rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  text-align: center;
  background-color: #3a845e;
  border: none;
  width: 12.5rem;
  float: left;
`

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const PriceWraper = styled.div`
  display: flex;
  align-items: center;
`

export const DiscountPercentage = styled.span`
  font-size: 0.625rem !important;
`
