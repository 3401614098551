import HomePage from '../pages/home'
import LoginPage from '../pages/login'
import UserActions from '../pages/user-actions'
import ResetPassword from '../pages/reset-password'
import EmailConfirmation from '../pages/email-confirmation'
import NewPatientPage from '../pages/patients/new-patient'
import NewPrescriptionConfirmPage from '../pages/prescriptions/new-prescription-confirm'
import NewPrescriptionPage from '../pages/prescriptions/new-prescription-marketplace'
import PatientPage from '../pages/patients/patient'
import PatientsPage from '../pages/patients'
import PrescriptionPage from '../pages/prescriptions/prescription'
import PrescriptionsPage from '../pages/prescriptions'
import PaymentPage from '../pages/settings/payment'
import BrandsPage from '../pages/settings/brands'
import AddressPage from '../pages/settings/address'
import RegisterPage from '../pages/register'
import RegisterCredentialsPage from '../pages/register/credentials'
import RecommendationPage from '../pages/recommendation'
import CartPage from '../pages/cart'
import ProductsPage from '../pages/products'
import FavoritesPage from '../pages/favorites'
import PublicProductsPage from '../pages/public-products'
import PatientProductsPage from '../pages/patient-products'
import OnboardingProfileInfo from '../pages/onboarding'
import ProtocolesPage from '../pages/protocoles'
import ProtocolePage from '../pages/protocoles/protocole'
import NewProtocolePage from '../pages/protocoles/NewProtocole'
import NewMarletplaceProtocolePage from '../pages/protocoles/new-marketplace-protocole'
import IntegrationsPage from '../pages/integrations/'
import OrdersPage from '../pages/orders'
import GainsPage from '../pages/gains'
import QuizzesPage from '../pages/quizzes'
import QuizPage from '../pages/quizzes/quiz'
import NotesPage from '../pages/notes'
import NewNotesPage from '../pages/notes/new-note'
import NotePage from '../pages/notes/note'
import PortailPage from '../pages/portail'
import RegisterPatientPage from '../pages/register-patient'
import EmailConfirmationRegister from '../pages/email-confirmation-register/EmailConfirmationRegister'
import RecommendationDetail from '../pages/portail/recommendations/recommendations-detail'
import PatientRecommendationsPage from '../pages/patient-recommendations/PatientRecommendationsPage'
import LoginPatientPage from '../pages/login-patient'
import PortailPatientProductsPage from '../pages/patient-products-portail'
import PortailCartPage from '../pages/patient-cart'
import PatientOrdersHistoryPage from '../pages/patient-orders-history/PatientOrdersHistoryPage'

const routes = [
  { c: HomePage, path: '/', auth: true },
  { c: PortailPage, path: '/portail' },
  { c: LoginPage, path: '/login' },
  { c: RegisterPage, path: '/register' },
  { c: LoginPatientPage, path: '/p/login' },
  { c: RegisterPatientPage, path: '/p/register' },
  { c: RecommendationDetail, path: '/p/recommendations/:id', auth: true },
  { c: EmailConfirmationRegister, path: '/p/register/confirm' },
  { c: LoginPatientPage, path: '/patient/login' },
  { c: RegisterPatientPage, path: '/patient/register' },
  { c: EmailConfirmationRegister, path: '/patient/register/confirm' },
  { c: RegisterCredentialsPage, path: '/register/credentials' },
  { c: UserActions, path: '/user-actions/:mode?/:oobcode?' },
  { c: ResetPassword, path: '/reset-password/:mode?/:oobcode?' },
  { c: EmailConfirmation, path: '/email-confirm/:mode?/:oobcode?' },
  { c: PatientsPage, path: '/patients', auth: true },
  { c: NewPatientPage, path: '/patients/new', auth: true },
  { c: PatientPage, path: '/patients/:id', auth: true },
  { c: PrescriptionsPage, path: '/prescriptions', auth: true },
  { c: NewPrescriptionPage, path: '/prescriptions/new', auth: true },
  { c: NewPrescriptionConfirmPage, path: '/prescriptions/confirm', auth: true },
  { c: PrescriptionPage, path: '/prescriptions/:id', auth: true },
  { c: PaymentPage, path: '/settings/payment', auth: true },
  { c: AddressPage, path: '/settings/address', auth: true },
  { c: BrandsPage, path: '/settings/brands', auth: true },
  { c: CartPage, path: '/cart', isPublic: true },
  { c: RecommendationPage, path: '/r/:prescriptionId', isPublic: true },
  {
    c: PatientRecommendationsPage,
    path: '/patient/recommendations',
    auth: true,
  },

  {
    c: PortailPatientProductsPage,
    path: '/patient/catalog',
    auth: true,
  },
  {
    c: PortailCartPage,
    path: '/patient/cart',
    auth: true,
  },
  {
    c: PatientOrdersHistoryPage,
    path: '/patient/orders',
    auth: true,
  },
  

  { c: ProductsPage, path: '/catalog', auth: true },
  { c: FavoritesPage, path: '/favorites', auth: true },
  { c: PublicProductsPage, path: '/public-catalog', isPublic: true },
  { c: PatientProductsPage, path: '/patient-catalog', isPublic: true },
  { c: OnboardingProfileInfo, path: '/onboarding/profile', auth: true },
  { c: ProtocolesPage, path: '/protocoles', auth: true },
  { c: NewProtocolePage, path: '/protocoles/new', auth: true },
  {
    c: NewMarletplaceProtocolePage,
    path: '/marketplace-protocoles/new',
    auth: true,
  },
  { c: ProtocolePage, path: '/protocoles/:id', auth: true },
  { c: IntegrationsPage, path: '/integrations', auth: true },
  { c: OrdersPage, path: '/orders', auth: true },
  { c: GainsPage, path: '/gains', auth: true },
  { c: QuizzesPage, path: '/quizzes', auth: true },
  { c: QuizPage, path: '/quizzes/:id', auth: true },
  { c: NotesPage, path: '/notes', auth: true },
  { c: NewNotesPage, path: '/notes/new', auth: true },
  { c: NotePage, path: '/note/:id', auth: true },
]

export default routes
