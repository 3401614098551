import HeaderOnlyLogo from '../../components/section/header-only-logo/HeaderOnlyLogo'
import {
  CardContainer,
  BodyContainer,
  MainContainer,
  Title,
  SubTitle,
} from './style'
import PortailCardRedirection from '../../components/PortailCardRedirection/PortailCardRedirection'

function PortailPage() {
  return (
    <BodyContainer>
      <HeaderOnlyLogo />
      <MainContainer>
        <Title>Connectez-vous avec Simplycure</Title>
        <SubTitle>
          A quel type de compte souhaitez-vous vous connecter?
        </SubTitle>
        <CardContainer>
          <PortailCardRedirection
            title='Compte professionnel'
            subtitle="Si vous êtes un professionnel de la santé souhaitant simplifier vos recommandations de compléments alimentaires pour vos clients, Simplycure est l'outil idéal."
            imgSrc='/assets/images/profesionnel.png'
            imgAlt='profesionnel-image'
            btnText='Je suis un profesionnel de la santé'
            redirectUrl='/login'
          />
          <PortailCardRedirection
            title='Compte client'
            subtitle='Accédez aux recommandations de votre praticien et à 100+ marques de compléments alimentaires validées par votre praticien. Le tout au meilleur prix.'
            imgSrc='/assets/images/patient.png'
            imgAlt='patient-image'
            btnText='Je suis un client'
            redirectUrl='/patient/login'
          />
        </CardContainer>
      </MainContainer>
    </BodyContainer>
  )
}

export default PortailPage
