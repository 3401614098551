import styled from 'styled-components/macro'

export const Title = styled.h2`
  font-size: 18px;
  margin-bottom: 25px;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  padding: 32px 28px;

  overflow: scroll;
  height: 500px;

  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`
export const Label = styled.label`
  font-size: 12px;
  opacity: 0.6;
  margin-bottom: 5px;
`
