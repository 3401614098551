import { useState } from 'react'
import { InfoBig } from '../../../ui/icon/icons'
import { RewardMonthlyCardProps } from './props'
import {
  ButtonShop,
  Container,
  ContentContainer,
  Description,
  HeaderContainer,
  Image1,
  InfoContainer,
  Title,
  TooltipIconContainer,
  InlineButton,
  DiscountWrapper,
  Bur,
  Button,
} from './style'
import SponsorFormDialog from '../../../sponsor/form-dialog'
import { useGetMe } from '../../../../utils/hooks/getMe'
import { useGetDiscountsByPrescriberId } from '../../../../services/api/prescriber/prescriber'
import { useHistory } from 'react-router'
import { getMaxPercentageItem } from '../../../../utils/helpers'

export const RewardMonthlyCard = ({
  title,
  description,
  size = 'SMALL',
  onTooltipClick,
  onSelect,
}: RewardMonthlyCardProps) => {
  // Attributes
  const [isFormOpened, setIsFormOpened] = useState<boolean>(false)
  const { data: prescriber } = useGetMe()
  const discounts = useGetDiscountsByPrescriberId(
    prescriber?.id,
    {},
    { query: { enabled: !!prescriber?.id } }
  )

  const discountCode = getMaxPercentageItem(
    discounts?.data?.data,
    prescriber?.commissionPercentage
  )

  const [isCopied, setIsCopied] = useState(false)

  const onCopyText = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 3000) // reset after 3 seconds
  }

  // Render
  return (
    <Container size={size}>
      {!prescriber?.isVerified && (
        <Bur>
          <p>
            Cette fonctionnalité n’est accessible que si votre compte est
            vérifié. Pour vérifier votre compte, veuillez prendre RDV sur le
            lien suivant
          </p>
          <ButtonShop
            href='https://share-eu1.hsforms.com/1xyWe3pUVTT6EZMmx4JTqGwf2dsm'
            target='_blank'
            style={{
              marginTop: '0px',
            }}
          >
            Prendre RDV
          </ButtonShop>
        </Bur>
      )}
      <ContentContainer size={size}>
        <HeaderContainer>
          <InfoContainer>
            <Title>
              Bénéficier de -{discountCode?.percentage}% sur tout le catalogue.
            </Title>

            <Description>
              En tant qu’utilisateur Simplycure, vous bénéficiez de -
              {discountCode?.percentage}% sur tout le catalogue avec le code “
              {discountCode?.code}”. Ce code de réduction ne peut être utilisé
              que par vous et pour votre consommation personnelle. Ce code peut
              également être utilisé avec vos bons d’achats.
            </Description>
            <Description>
              Pour commander vos produits, vous pouvez introduire le code de
              réduction au moment du paiement de votre recommandation ou
              utiliser le catalogue patient en cliquant sur le lien ci-dessous.
            </Description>
            <InlineButton
              href='https://complimentme.notion.site/FAQ-de-www-compliment-pro-com-c6353b81340349768455c487d07aa200#c52b79d2c0134d9c80ee4dd8eedec836'
              target='blank'
            >
              Plus d’info dans notre FAQ
            </InlineButton>
            <Button
              onClick={() => {
                window.open(
                  `/patient-catalog?discountCode=${discountCode?.code}`,
                  '_blank'
                )
              }}
            >
              Commander pour soi-même
            </Button>
          </InfoContainer>
          {onTooltipClick && (
            <TooltipIconContainer>
              <InfoBig
                onClick={onTooltipClick}
                width='1.75rem'
                height='1.75rem'
                stroke='#FFFFFF'
              />
            </TooltipIconContainer>
          )}
        </HeaderContainer>
      </ContentContainer>

      <DiscountWrapper>-{discountCode?.percentage}%</DiscountWrapper>
      <Image1
        size={size}
        src='/assets/images/monthly-card-4.png'
        width='auto'
        height='166px'
      />

      {isFormOpened && (
        <SponsorFormDialog
          isOpen={isFormOpened}
          onClose={() => setIsFormOpened(false)}
          formId={'b1a762c1-83de-4738-a970-573a510ffd34'}
          prescriber={prescriber}
          succeesMessageTitle='Merci ! Votre demande a bien été enregistré.'
        />
      )}
    </Container>
  )
}

export default RewardMonthlyCard
