import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  width: 49%;
  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  margin-top: 20px;
  background-color: yellow;
  width: 100%;
  height: 600px;
  overflow: scroll;

  margin-top: 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 15px;
  padding: 25px 30px;
`

export const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`

export const List = styled.div``

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const Right = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Date = styled.div`
  font-size: 12px;
  opacity: 0.5;
  margin-top: 3px;
`

export const TextWrapper = styled.div`
  width: 100%;
  h3 {
    margin: 3px 0;
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
`

export const Button = styled.a`
  cursor: pointer;
  border-radius: 8px;
  background-color: transparent;
  color: inherit;
  padding: 8px;
  font-size: 11px;
  text-decoration: underline;
`

export const Img = styled.img`
  width: 50px;
  margin-right: 20px;
`

export const EventType = styled.span`
  font-size: 12px;
  color: #3a835f;
`
